// NODE MODULES
import React from 'react';
import Layout from "../layouts/pl";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade';

// COMPONENTS
import PhotoGallery from '../components/Gallery';

const OfertaSpecjalna = (props) => {

  const data = useStaticQuery(graphql` 
    query {
        desktopImage: file(relativePath: { eq: "images/photos/oferta/ofertaSpecjalna-new11.jpg" }) {
            childImageSharp {
                fluid(quality: 100){
                ...GatsbyImageSharpFluid
                }
            }   
        }
        mobileImage: file(relativePath: { eq: "images/photos/oferta/ofertaSpecjalna-new11-mobile.jpg" }) {
            childImageSharp {
                fluid(quality: 50){
                ...GatsbyImageSharpFluid
                }
            }   
        }
    }
  `);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Layout location={props.location}>
      <Fade up>
        <section className="area" id="section2">
          <Img fluid={sources} alt="Dziwnowek apartamenty Amber" />
          <h1 className="title">OFERTA SPECJALNA</h1>
          <div className="content-container">
            <p> Zapraszamy Państwa do spędzenia w naszych apartamentach Świat Wielkanocnych, Bożonarodzeniowych, Sylwestra oraz pozostałych wyjątkowych weekendów i dni.</p>
            <p>Na te specjalne okazje nasze apartamenty są szczególnie przystrajane i wyposażane w odpowiednie elementy dekoracyjne tak abyście poczuli się Państwo jak w domu.</p>
            <p>Posiadamy również możliwość zorganizowania śniadań, kolacji wigilijnej, dań świątecznych, przystawek Sylwestrowych oraz innych, według potrzeb, posiłków w formie cateringu – odpowiednio podanego i dostarczonego do apartamentów.</p>
            <p>W naszej ofercie znajdziecie Państwo także vouchery do wykorzystania na pobyt w naszych apartamentach, które doskonale nadają się jako prezent dla bliskich nam osób.</p>
          </div>
        </section>
      </Fade>
      <Fade up>
        <section className="photos" id="section3">
          <h1>Galeria</h1>
          <div className="photos">
            <PhotoGallery galleryType='ofertySpecjalne' />
          </div>
        </section>
      </Fade>
    </Layout>
  )
}

export default OfertaSpecjalna
